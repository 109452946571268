import React from "react";
import Layout from "@components/layouts/Layout";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

// Fix para que funciona ReactJson
const ReactJsonView = typeof window !== `undefined` ? require("react-json-view").default : null;

const Component = () => {
    return (
        <Layout seo={{ title: "Carrito de la compra" }}>
            <SmallBanner title="Carrito de la compra" filename="bgs/bgCart" />
            <div className="container lg:px-0 py-20 space-y-5">
                <div>
                    <Title className="text-left">Resumen del pedido</Title>
                    <Text className="text-justify">
                        A continuación te mostramos un listado de los productos que tienes en la
                        cesta de la compra, así como sus precios, descuentos y gastos de envío (en
                        caso que el producto sea físico). Recuerda que para realizar el pedido has
                        de rellenar todos los datos que hay en el formulario de{" "}
                        <i>Datos del envío</i>.
                    </Text>
                </div>

                {process.env.NODE_ENV === "development" && (
                    <ReactJsonView src={{}} collapsed={true} />
                )}
            </div>
        </Layout>
    );
};

export default Component;
